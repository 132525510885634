/* About.css */
.about-container {
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: var(--body-font-mono);
  font-size: var(--body-font-size);
  margin: var(--margin);
}

.about-content {
  width: 73%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#dev {
  margin-top: var(--margin);
}

.about-description {
  margin-bottom: var(--margin);
  max-width: 50%;
  text-align: left;
  align-items: flex-start;
}

.about-subtitle {
  font-size: var(--body-font-size);
  font-family: var(--body-font-mono-semibold);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 4rem;
}

.landscape {
  grid-column: span 2;
  grid-row: 1;
}

.portrait {
  grid-column: span 1;
}

.image-grid figure {
  margin: 0;
  position: relative;
}

.image-grid img {
  width: 100%;
}

.figcaption {
  text-align: center; /* Center the caption text */
  margin-bottom: var(--margin);
  font-style: italic; /* Italicize the caption (optional) */
}

.bold {
  font-family: var(--body-font-mono-bold);
}

@media (max-width: 768px) {
  .about-container {
    width: 80%;
  }

  .image-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .landscape {
    grid-column: span 1;
  }

  .about-description {
    max-width: 100%;
  }
}
