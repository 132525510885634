.mobile-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--margin);
  margin-bottom: calc(var(--margin) * var(--golden-ratio));
  font-size: var(--title-font-size);
}
