:root {
  --body-font: "Geist";
  --body-font-semibold: "Geist-Semibold";
  --body-font-medium: "Geist-Medium";

  --body-font-mono: "Geist-Mono";
  --body-font-mono-medium: "Geist-Mono-Medium";
  --body-font-mono-semibold: "Geist-Mono-Semibold";
  --body-font-mono-bold: "Geist-Mono-Bold";
  --golden-ratio: 1.618;

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 800;

  --body-font-size: 0.8rem;
  --navbar-font-size: calc(var(--body-font-size) * var(--golden-ratio));
  --footer-font-size: calc(var(--body-font-size) * var(--golden-ratio));
  --title-font-size: calc(var(--navbar-font-size) * var(--golden-ratio));
  --tag-font-size: var(--body-font-size);
  --margin: 2rem;

  --background-color: white;
  --font-color: black;

  --tag-color-2: #478723;
  --tag-color-3: #234787;
  --tag-color-1: #872347;

  /* --mobile-font-size: 0.5rem; */
}

body {
  height: fit-content;
  margin: 0;
  font-family: var(--body-font);
  font-weight: var(--font-weight-regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

.sketch {
  width: 100%;
  height: 100vh;
  border: none;
  /* border: 1px solid white; */
  margin-top: var(--margin);
}

.underline {
  text-decoration: underline;
}

a {
  color: var(--font-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* TRANSITIONS*/
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

@font-face {
  font-family: "Geist";
  src: url("../public/Geist/GeistVariableVF.woff2"),
    url("../public/Geist/GeistVariableVF.ttf");
}

@font-face {
  font-family: "Geist-SemiBold";
  src: url("../public/Geist/Geist-SemiBold.woff2");
  /* url("../public/Geist/GeistVariableVF.ttf"); */
}

@font-face {
  font-family: "Geist-Medium";
  src: url("../public/Geist/Geist-Medium.woff2");
  /* url("../public/Geist/GeistVariableVF.ttf"); */
}

@font-face {
  font-family: "Geist-Mono";
  src: url("../public/Geist.Mono/GeistMonoVariableVF.woff2"),
    url("../public/Geist.Mono/GeistMonoVariableVF.ttf");
}

@font-face {
  font-family: "Geist-Mono-Medium";
  src: url("../public/Geist.Mono/GeistMono-Medium.otf");
}

@font-face {
  font-family: "Geist-Mono-SemiBold";
  src: url("../public/Geist.Mono/GeistMono-SemiBold.otf");
}

@font-face {
  font-family: "Geist-Mono-Bold";
  src: url("../public/Geist.Mono/GeistMono-Bold.woff2");
  /* url("../public/Geist/GeistVariableVF.ttf"); */
}

@media (max-width: 768px) {
  :root {
    --body-font-size: 0.5rem;
    --margin: 0.8rem;
  }

  .sketch {
    width: 100vw;
    height: auto;
  }
}
