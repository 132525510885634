.work-container {
  margin: var(--margin);
  margin-bottom: calc(var(--margin) * var(--golden-ratio));
  display: flex;
  align-items: center;
  flex-direction: column;
}

.case-study-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.work {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: var(--navbar-font-size);
  font-family: var(--body-font-semibold);
  /* font-family:  */
}

.subtitle {
  font-size: var(--body-font-size);
  font-family: var(--body-font-mono);
  max-width: 45%;
}

.subheading {
  font-family: var(--body-font-semibold);
  font-size: var(--navbar-font-size);
}

.description {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: calc(var(--margin) / var(--golden-ratio));
  justify-content: space-between;
}

.case-study {
  width: 73%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--margin);
  cursor: pointer;
}

.case-study-image {
  width: 100%;
  height: 10%;
  margin-bottom: calc(var(--margin) / var(--golden-ratio));
}

.tags {
  font-family: var(--body-font-mono);
  font-size: var(--tag-font-size);
  list-style-type: none;
  padding: 0;
  display: flex;
  height: fit-content;
  gap: calc(var(--margin) / var(--golden-ratio));
  text-align: center;
  margin: 0;
  color: var(--background-color);
}

.tags li {
  display: inline-block;
  font-size: var(--tag-font-size);
  padding: 5px;
}

.myButton {
  font-size: var(--body-font-size);
  display: inline-block;
  background-color: white;
  color: black;
  padding-left: 0.15rem;
  padding-right: 0.15rem;
  padding-top: 0.225rem;
  border: 1px solid black;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.myButton:hover {
  background-color: black;
  color: white;
  border: 1px solid black;
}

.myButton a {
  text-decoration: none;
  color: inherit;
}

.myButton a:hover {
  /* text-decoration: none; */
  color: inherit;
}

@media (max-width: 768px) {
  .case-study {
    width: 65%;
  }

  .subtitle {
    max-width: 100%;
    margin-top: calc(var(--margin) / var(--golden-ratio));
  }

  .tags li {
    padding: 2.5px;
  }

  .title {
    font-family: var(--body-font-medium);
  }

  .subheading {
    font-family: var(--body-font-medium);
  }
}
