.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  margin: var(--margin);
  font-size: var(--navbar-font-size);
  font-family: var(--body-font);
  color: var(--font-color);
  display: flex;
  flex-direction: column;
  gap: var(--margin);
  width: 10%;
  z-index: 2;
  /* border: 1px solid black; */
}

.navbar-left {
  font-family: var(--body-font-semibold);
}

.navbar-right {
  display: flex;
  flex-direction: column;
  /* gap: calc(var(--margin) / var(--golden-ratio)); */
}

.navbar-component {
  cursor: pointer;
  color: var(--font-color);
  width: min-content;
  /* box-shadow: inset 0 0 0 0 var(--background-color);
  transition:
    color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out; */
  background-color: var(--background-color);
  background-image: linear-gradient(
    to right,
    var(--font-color) 0%,
    var(--font-color) 100%
  );
  background-size: 0 100%; /* Initially hidden */
  background-position: left bottom;
  background-repeat: no-repeat;
  transition:
    background-size 0.3s ease-in-out,
    color 0.3s ease-in-out;
}

.navbar-component:hover {
  /* box-shadow: inset 90px 0 0 0 var(--font-color);
  color: var(--background-color); */
  background-size: 100% 100%; /* Expands background */
  color: var(--background-color);
}

@media (max-width: 768px) {
  .navbar-container {
    width: 17%;
  }

  .navbar-left {
    font-family: var(--body-font-medium);
  }
}
