.content-container {
  display: flex;
  flex-direction: row;
  margin: var(--margin);
  margin-bottom: calc(
    var(--margin) * var(--golden-ratio)
  ); /* Consistent spacing */
  font-size: var(--body-font-size);
  font-family: var(--body-font-mono);
  position: relative;
  z-index: 0;
}

.content {
  width: 73%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.content-nav {
  max-width: 10%;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}

.content-nav ul {
  list-style-type: none;
  padding: 0;
}

.content-nav a {
  text-decoration: none;
  font-family: var(--body-font-mono-semibold);
  font-size: var(--body-font-size);
  background-color: var(--background-color);
  background-image: linear-gradient(
    to right,
    var(--font-color) 0%,
    var(--font-color) 100%
  );
  background-size: 0 100%; /* Initially hidden */
  background-position: left bottom;
  background-repeat: no-repeat;
  transition:
    background-size 0.3s ease-in-out,
    color 0.3s ease-in-out;
}

.content-nav a:hover {
  background-size: 100% 100%; /* Expands background */
  color: var(--background-color); /* Change text color */
}

.content-nav li {
  padding-bottom: calc(var(--body-font-size) / 3);
}

.content-details {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content-image {
  width: 100%;
  margin-bottom: calc(var(--margin) / var(--golden-ratio));
}

.content-title {
  position: fixed;
  top: var(--margin);
  left: 15%;
  max-width: 35%;
  font-size: var(--navbar-font-size);
  font-family: var(--body-font-semibold);
  margin-bottom: calc(var(--margin) / var(--golden-ratio));
  z-index: 1;
}

.content-subtitle {
  /* font-family: var(--body-font-mono); */
  margin-bottom: calc(var(--margin) / var(--golden-ratio));
}

/* Additional styling for tags if used in Content component */
.content-tags {
  font-family: var(--body-font-mono);
  font-size: var(--tag-font-size);
  list-style-type: none;
  padding: 0;
  display: flex;
  height: fit-content;
  gap: calc(var(--margin) / var(--golden-ratio));
  text-align: center;
  margin-bottom: calc(var(--margin) / var(--golden-ratio));
  color: var(--background-color);
}

.content-tags li {
  display: inline-block;
  font-size: var(--tag-font-size);
  padding: 5px;
}

.content-description {
  display: flex;
  flex-direction: column;
  /* max-width: 100%; */
  /* gap: calc(var(--margin) / var(--golden-ratio)); */
}

#role {
  margin-top: calc(
    var(--margin) + var(--navbar-font-size) + var(--body-font-size) + 1.5rem
  );
}

#casestudy-button {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.section {
  display: flex;
  align-items: flex-start;
  flex-direction: row; /* Align items at the start of the flex container */
  justify-content: space-between;
  gap: var(--margin);
  margin-top: var(--margin);
  /* margin-bottom: var(--margin); */
}

.section-text {
  max-width: 35%;
  flex: 1;
}

.images-container {
  display: flex;
  flex-direction: column;
  width: 65%;
}

.images-container img {
  width: 100%;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  margin-bottom: calc(var(--margin) / var(--golden-ratio));
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.section-heading {
  font-family: var(--body-font-mono-semibold);
  /* margin-bottom: calc(
    var(--margin) / (var(--golden-ratio) * var(--golden-ratio))
  ); */
}

@media (max-width: 768px) {
  .section-text {
    max-width: 100%;
  }

  .content-title {
    font-family: var(--body-font-mono-medium);
  }

  .images-container {
    max-width: 100%;
  }

  .section-heading {
    font-family: var(--body-font-mono-medium);
  }

  .section {
    flex-direction: column;
  }

  .content {
    width: 60%;
  }

  .content-tags li {
    padding: 2.5px;
  }
}
