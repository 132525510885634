.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  font-family: var(--body-font-mono);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    transform 1s ease-in-out,
    opacity 1s ease-in-out;
  opacity: 1;
}

.typewriter-container {
  color: var(--font-color);
  font-size: var(--body-font-size);
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  position: relative;
  z-index: 11;
}

.typewriter-effect {
  position: absolute;
  left: calc(100% + 0.4rem);
  white-space: nowrap;
}

.fixed-phrase {
  /* margin-right: 0.5rem; */
  white-space: nowrap;
  font-family: var(--body-font-mono-medium);
}

.loading-overlay.hide {
  transform: translateY(-100%);
  opacity: 0;
}
